"use client";

import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { SplashScreen as SplashScreenComponent } from "@gonoodle/gn-universe-ui";
import { usePathname } from "next/navigation";

import { useAppConfig } from "../contexts/appConfig";
import { useSplashScreen, useImpression } from "../hooks";
import { IMPRESSION_TYPES, ROUTE_PREFIX } from "../constants";

const SplashScreen = ({ children }) => {
  const pathname = usePathname();
  const { splashScreen, onLoadComplete } = useSplashScreen();
  const { appConfig } = useAppConfig();
  const { adProductSplashScreen } = appConfig;
  const isSuperNoodle = pathname.startsWith(`/${ROUTE_PREFIX.CURRICULUM}`);

  const ref = useImpression({
    properties: {
      impressionType: IMPRESSION_TYPES.LOGO,
      adProductType: "splash_screen",
      partnerName: splashScreen?.partnerName,
      partnerId: splashScreen?.partnerId,
      adProductPartnerId: splashScreen?.id,
    },
    threshold: 0.5,
    enabled: !!splashScreen && !isSuperNoodle,
  });

  if (isSuperNoodle) {
    return children;
  }

  return (
    <>
      {!isEmpty(splashScreen) && adProductSplashScreen.visible ? (
        <SplashScreenComponent
          ref={ref}
          src={splashScreen?.images?.splashScreenImage}
          alt={splashScreen?.partnerName}
          onLoad={onLoadComplete}
          onError={onLoadComplete}
        />
      ) : (
        children
      )}
    </>
  );
};

SplashScreen.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SplashScreen;
