"use client";

import React from "react";
import { QueryClientProvider, HydrationBoundary } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import {
  AssetsConfigProvider,
  OverlayProvider,
  Alert,
} from "@gonoodle/gn-universe-ui";

import OneTrustProvider from "../OneTrust";
import ReferrerProvider from "../Referrer";
import AppConfigProvider from "../appConfig";
import UserProvider from "../user";
import PianoProvider from "../Piano";
import AnalyticsProvider from "../Analytics";
import BeaconProvider from "../Beacon";
import TransmogrifierProvider from "../Transmogrifier";
import SplashScreen from "../../components/SplashScreen";
import getQueryClient from "../../helpers/getQueryClient";
import { URLS, ELEMENT_IDS } from "../../constants";

const ASSETS_CONFIG = {
  assetsUrl: URLS.GN_ASSETS_BASE_URL,
};

export default function Providers({ children, dehydratedState }) {
  const [navbarPosition, setNavbarPosition] = React.useState(null); // Null if no navbar

  const queryClient = getQueryClient();

  React.useEffect(() => {
    const updateNavbarPosition = () => {
      const navbar = document.getElementById(ELEMENT_IDS.NAVBAR);
      if (navbar) {
        setNavbarPosition(navbar.getBoundingClientRect().bottom);
      } else {
        setNavbarPosition(null); // No navbar present
      }
    };

    updateNavbarPosition();

    window.addEventListener("resize", updateNavbarPosition);

    const navbar = document.getElementById(ELEMENT_IDS.NAVBAR);
    const resizeObserver = navbar
      ? new ResizeObserver(updateNavbarPosition)
      : null;
    if (resizeObserver) resizeObserver.observe(navbar);

    return () => {
      window.removeEventListener("resize", updateNavbarPosition);
      if (resizeObserver) resizeObserver.disconnect();
    };
  }, []);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_ENTERPRISE_KEY}
      scriptProps={{
        async: true,
        useEnterprise: true,
      }}
    >
      <OneTrustProvider>
        <QueryClientProvider client={queryClient}>
          <AppConfigProvider>
            <ReferrerProvider>
              <HydrationBoundary state={dehydratedState}>
                <UserProvider>
                  <PianoProvider>
                    <AnalyticsProvider>
                      <OverlayProvider>
                        <BeaconProvider>
                          <AssetsConfigProvider value={ASSETS_CONFIG}>
                            <Alert.Provider
                              offset={
                                navbarPosition !== 0 ? navbarPosition + 10 : 20
                              }
                            >
                              <TransmogrifierProvider>
                                <SplashScreen>{children}</SplashScreen>
                              </TransmogrifierProvider>
                            </Alert.Provider>
                          </AssetsConfigProvider>
                        </BeaconProvider>
                      </OverlayProvider>
                    </AnalyticsProvider>
                  </PianoProvider>
                </UserProvider>
              </HydrationBoundary>
            </ReferrerProvider>
          </AppConfigProvider>

          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </OneTrustProvider>
    </GoogleReCaptchaProvider>
  );
}
