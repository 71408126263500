"use client";

import React from "react";
import PropTypes from "prop-types";
import Script from "next/script";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSearchParams, usePathname } from "next/navigation";

import BugsnagClient from "../../utils/bugsnag";
import { useUser } from "../user";
import { USER_TYPES, QUERY_KEYS, ROUTE_PREFIX } from "../../constants";
import api from "../../api";

const fetcher = api();

export default function Provider({ children }) {
  const { user } = useUser();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const queryClient = useQueryClient();
  const [isExperienceInitialized, setIsExperienceInitialized] =
    React.useState(false);

  const contractId = searchParams.get("contractId");

  const { mutate: redeemContract } = useMutation({
    mutationFn: () => fetcher.redeemContract(contractId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.USER, QUERY_KEYS.USER_ACCESSES],
      });
    },
  });

  React.useEffect(() => {
    // Allows buffering of commands before Piano script loads
    window.tp = window.tp || [];
  }, []);

  React.useEffect(() => {
    window.tp.push([
      "setComposerHost",
      process.env.NEXT_PUBLIC_PIANO_COMPOSER_HOST,
    ]);
    window.tp.push(["setPianoIdUrl", process.env.NEXT_PUBLIC_PIANO_ID_URL]);
    window.tp.push(["setEndpoint", process.env.NEXT_PUBLIC_PIANO_ENDPOINT]);
    window.tp.push([
      "setStaticDomain",
      process.env.NEXT_PUBLIC_PIANO_CDN_DOMAIN,
    ]);
  }, []);

  React.useEffect(() => {
    if (!user.pianoAuthToken) {
      window.tp.push(["setExternalJWT", undefined]);
    }
  }, [user.pianoAuthToken]);

  React.useEffect(() => {
    const currentUserType = Object.values(USER_TYPES).find(
      (userType) => userType.id === user.userTypeId,
    );

    if (user.features.pianoReceiveUserData) {
      window.tp.push(["setCustomVariable", "userType", currentUserType?.name]);
    }
  }, [user.userTypeId, user.features.pianoReceiveUserData]);

  /**
   * Initialize Piano experience and set external JWT token.
   * This effect ignores the user.pianoAuthToken and user.features.pianoReceiveUserData dependency, as syncing the token
   * runs on page navigation. This will cause tabs to include stale token data, but that is acceptable from the product team.
   * If issues arise, from the fact that server state is not in sync with client state, this effect should be updated.
   */
  React.useEffect(() => {
    if (user.features.pianoReceiveUserData) {
      window.tp.push(["setExternalJWT", user.pianoAuthToken]);
    }

    window.tp.push([
      "init",
      () => {
        window.tp.experience.init();
        setIsExperienceInitialized(true);
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isExperienceInitialized) {
      if (user.features.pianoReceiveUserData) {
        window.tp.push(["setExternalJWT", user.pianoAuthToken]);
      }
      window.tp.experience.execute();
    }
  }, [
    pathname,
    isExperienceInitialized,
    user.pianoAuthToken,
    user.features.pianoReceiveUserData,
  ]);

  React.useEffect(() => {
    let script = undefined;

    if (
      user.features.piano &&
      user.isLoggedIn &&
      pathname === ROUTE_PREFIX.HOME &&
      contractId
    ) {
      script = document.createElement("script");
      script.src =
        "https://cdnjs.cloudflare.com/ajax/libs/angular.js/1.2.22/angular.min.js";
      script.async = true;

      script.onerror = () => {
        redeemContract(contractId);
      };

      document.body.appendChild(script);
    }

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [
    redeemContract,
    user.isLoggedIn,
    pathname,
    user.features.piano,
    contractId,
  ]);

  return (
    <>
      {user.features.piano ? (
        <Script
          src={`${process.env.NEXT_PUBLIC_PIANO_SCRIPT_URL}?aid=${process.env.NEXT_PUBLIC_PIANO_APPLICATION_ID}`}
          onError={(event) => {
            BugsnagClient.notify({
              name: "Piano script failed to load",
              message: `${event.target.src} failed to load`,
            });
          }}
          strategy="afterInteractive"
        />
      ) : null}

      {children}
    </>
  );
}

Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
