"use client";

import React from "react";
import { FiveHundred as PrimitiveFiveHundred } from "@gonoodle/gn-universe-ui";

import AppBar from "../components/AppBar";
import Providers from "../contexts/Providers";
import BugsnagClient from "../utils/bugsnag";

export default function FiveHundred({ error }) {
  React.useEffect(() => {
    BugsnagClient.notify(error);
  }, [error]);

  return (
    <html>
      <body>
        <Providers>
          <AppBar />

          <PrimitiveFiveHundred />
        </Providers>
      </body>
    </html>
  );
}
